import {
  getPaginated,
  PaginatedRequest,
  PaginatedResponse,
} from "@/application/api/getPaginated";
import { AxiosResponse } from "axios";
import mapIndexResponseErrors from "@/application/util/mapIndexResponseErrors";

export interface StockIndexItem {
  id: number;
  dimProduct: {
    articleNumber?: string;
    productName?: string;
    totalStock: number;
    availableStock: number;
    onHoldStock: number;
    inTransitStock: number;
    inboundStock: number;

  };
  stockMinimum: number;
  stockStatus: string | null;
  stockLastMonth: number;
  stockLastQuarter: number;
  stockCurrentYear: number;
  stockLastYear: number;
  status: string;
}

export const clientStockIndex = async (
  data: PaginatedRequest,
  clientId: number
): Promise<AxiosResponse<PaginatedResponse<StockIndexItem[]>>> => {
  const response = await getPaginated(`client/${clientId}/stock`, data);
  response.data.data = mapIndexResponseErrors(response.data.data);

  return response;
};
