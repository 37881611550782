
import Vue, { VueConstructor } from "vue";
import KCrudTable, {
  CrudTableHeader,
} from "@/modules/crudTable/components/KCrudTable.vue";
import { ExportType, PaginatedRequest } from "@/application/api/getPaginated";
import { mapGetters } from "vuex";
import RequiredClientDialog from "@/modules/client/components/RequiredClientDialog.vue";
import {
  clientStockIndex,
  StockIndexItem,
} from "@/modules/stock/api/stockIndex";
import StockFilter from "@/modules/stock/components/StockFilter.vue";
import ExportButton from "@/components/ExportButton.vue";

interface ComponentData {
  headers: CrudTableHeader[];
  selected: Partial<StockIndexItem> & { clientId: number; id: number }[];
  defaultFilters: Record<string, unknown>;
  exports: ExportType[];
  stockIndexData: StockIndexItem[];
  filterComponent: VueConstructor;
}

export default Vue.extend({
  name: "StockIndexTable",
  components: {
    ExportButton,
    RequiredClientDialog,
    KCrudTable,
  },
  data: (): ComponentData => ({
    headers: [
      { value: "dimProduct.articleNumber", sortable: false },
      { value: "dimProduct.productName", sortable: false },
      { value: "dimProduct.totalStock", sortable: false },
      { value: "dimProduct.onHoldStock", sortable: false },
      { value: "dimProduct.availableStock", sortable: false },
      { value: "dimProduct.inTransitStock", sortable: false },
      { value: "dimProduct.inboundStock", sortable: false },
      { value: "stockMinimum", sortable: true },
      { value: "stockStatus", sortable: true },
      { value: "stockLastMonth", sortable: true },
      { value: "stockLastQuarter", sortable: true },
      { value: "stockCurrentYear", sortable: true },
      { value: "stockLastYear", sortable: true },
      { value: "status", sortable: true },
    ],
    selected: [],
    exports: [],
    defaultFilters: {},
    stockIndexData: [],
    filterComponent: StockFilter,
  }),
  computed: {
    ...mapGetters("authorisation", ["client", "isClient"]),
    ...mapGetters("crudTable", ["getRouteSetting"]),
  },
  watch: {
    client: {
      handler() {
        this.refreshTable();
      },
      deep: true,
    },
  },
  methods: {
    chipColor(value: string) {
      const options: Record<string, string> = {
        ZERO: "error",
        LOW: "warning",
        default: "success",
      };

      return options[value] || options.default;
    },
    async stockIndex(data: PaginatedRequest) {
      if (!this.client?.id) throw "Client id is not set";
      const response = await clientStockIndex(data, this.client?.id);
      this.stockIndexData = response.data.data;
      this.exports = response.data.export ?? [];
      return response;
    },
    refreshTable(): void {
      (this.$refs.table as any).getData();
    },
  },
});
